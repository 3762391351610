<template>
  <div
    class="modal fade"
    id="filterModal"
    tabindex="-1"
    aria-labelledby="filterModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-title" id="filterModalLabel">Filtros</h5>
          <button
            type="button"
            class="btn-close p-0"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <img src="../../assets/img/icons/close-circle-2.svg" alt="Image" />
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="applyFilters">
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group mb-15">
                  <label class="d-block fs-14 text-black mb-10"
                    >Nombre del Producto</label
                  >
                  <input
                    type="text"
                    v-model="filters.name"
                    class="w-100 d-block shadow-none fs-14 bg_ash rounded-1 text-black border-0 placeholder-1"
                    placeholder="Ingresa el nombre del producto"
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mb-15">
                  <label class="d-block fs-14 text-black mb-10"
                    >Categoría</label
                  >
                  <input
                    type="text"
                    v-model="filters.category"
                    class="w-100 d-block shadow-none fs-14 bg_ash rounded-1 text-black border-0 placeholder-1"
                    placeholder="Ingresa el nombre de la categoria"
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mb-15">
                  <label class="d-block fs-14 text-black mb-10"> SKU </label>
                  <input
                    type="text"
                    v-model="filters.sku"
                    class="w-100 d-block shadow-none fs-14 bg_ash rounded-1 text-black border-0 placeholder-1"
                    placeholder="Ingresa el SKU del producto"
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mb-15">
                  <label class="d-block fs-14 text-black mb-10">Marca</label>
                  <input
                    type="text"
                    v-model="filters.brand"
                    class="w-100 d-block shadow-none fs-14 bg_ash rounded-1 text-black border-0 placeholder-1"
                    placeholder="Ingresa la marca del producto"
                  />
                </div>
              </div>
              <div class="col-lg-12 d-flex justify-content-center">
                <button
                  type="submit"
                  class="btn style-five text-center"
                  data-bs-dismiss="modal"
                >
                  Aplicar Filtros
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterModal",
  data() {
    return {
      filters: {
        name: "",
        category: "",
        sku: "",
        brand: "",
      },
    };
  },
  methods: {
    applyFilters() {
      this.$emit("apply-filters", this.filters);
    },
  },
};
</script>
