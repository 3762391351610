<template>
  <MainHeader />
  <MainSidebar />
  <div
    class="main-content bg_gray d-flex flex-column transition overflow-hidden"
  >
    <BreadcrumbMenu pageTitle="Editar Cotización" />
    <div class="row mb-40">
      <div class="col-lg-4">
        <div class="form-group mb-25">
          <label class="d-block fs-14 text-black mb-2">Fecha</label>
          <input
            type="date"
            class="w-100 h-55 bg_ash border-0 rounded-1 fs-14 text-black bg-white"
          />
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group mb-25">
          <label class="d-block fs-14 text-black mb-2">Nombre Vendedor</label>
          <input
            type="text"
            class="w-100 d-block shadow-none fs-14 bg-white rounded-1 text-title"
            placeholder="Ingresar Nombre del Vendedor"
            required
          />
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group mb-25">
          <label class="d-block fs-14 text-black mb-2">Casa Matriz</label>
          <select class="bg-white border-0 rounded-1 fs-14 text-optional">
            <option value="0">Seleccionar Casa Matriz</option>
            <option value="1">Sucursal Principal</option>
          </select>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group mb-25">
          <label class="d-block fs-14 text-black mb-2">Email Vendedor</label>
          <input
            type="text"
            class="w-100 d-block shadow-none fs-14 bg-white rounded-1 text-title"
            placeholder="Ingresar Email del Vendedor"
            required
          />
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group mb-25">
          <label class="d-block fs-14 text-black mb-2">Teléfono Vendedor</label>
          <input
            type="text"
            class="w-100 d-block shadow-none fs-14 bg-white rounded-1 text-title"
            placeholder="Ingresar Teléfono del Vendedor"
            required
          />
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group mb-25">
          <label class="d-block fs-14 text-black mb-2">Nombre Cliente</label>
          <input
            type="text"
            class="w-100 d-block shadow-none fs-14 bg-white rounded-1 text-title"
            placeholder="Ingresar Nombre del Cliente"
            required
          />
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group mb-25">
          <label class="d-block fs-14 text-black mb-2">Email Cliente</label>
          <input
            type="text"
            class="w-100 d-block shadow-none fs-14 bg-white rounded-1 text-title"
            placeholder="Ingresar Email del Cliente"
            required
          />
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group mb-25">
          <label class="d-block fs-14 text-black mb-2">Teléfono Cliente</label>
          <input
            type="text"
            class="w-100 d-block shadow-none fs-14 bg-white rounded-1 text-title"
            placeholder="Ingresar Teléfono del Cliente"
            required
          />
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group mb-25">
          <label class="d-block fs-14 text-black mb-2">Dirección Cliente</label>
          <input
            type="text"
            class="w-100 d-block shadow-none fs-14 bg-white rounded-1 text-title"
            placeholder="Ingresar Dirección del Cliente"
            required
          />
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label class="d-block fs-14 text-black mb-2"
            >Seleccionar Producto</label
          >
          <div class="search-area style-two position-relative w-100">
            <input
              type="text"
              placeholder="Escanear / Buscar producto por código de barra"
              class="w-100 h-55 bg_ash border-0 rounded-1 fs-14 text-black bg-white"
            />
            <button
              type="submit"
              class="bg-transparent border-0 position-absolute top-0 end-0 h-100 pt-0 py-0 px-2"
            >
              <img src="../../assets/img/icons/search.svg" alt="Image" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <SelectedProducts />
    <SubmitQuotation isEditView="true" />

    <div class="flex-grow-1"></div>
    <MainFooter />
  </div>

  <div
    class="delete-popup offcanvas offcanvas-end border-0"
    tabindex="-1"
    id="deletePopup"
  >
    <div class="offcanvas-body p-0">
      <div class="delete-success">
        <img src="../../assets/img/icons/tick-circle.svg" alt="Image" />
        <span class="text-white fw-medium">
          Your product is deleted from the list.
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import MainHeader from "../../components/Layouts/MainHeader.vue";
import MainSidebar from "../../components/Layouts/MainSidebar.vue";
import BreadcrumbMenu from "../../components/Common/BreadcrumbMenu.vue";
import SelectedProducts from "../../components/Quotations/CreateQuotation/SelectedProducts.vue";
import SubmitQuotation from "../../components/Quotations/CreateQuotation/SubmitQuotation.vue";
import MainFooter from "../../components/Layouts/MainFooter.vue";

export default defineComponent({
  name: "EditQuotationPage",
  components: {
    MainHeader,
    MainSidebar,
    BreadcrumbMenu,
    SelectedProducts,
    SubmitQuotation,
    MainFooter,
  },
});
</script>
