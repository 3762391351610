<template>
  <form @submit.prevent="submitForm" ref="productForm" class="pb-60">
    <div class="row">
      <div class="col-xxl-9 col-xl-8 col-lg-8 pe-xxl-6 mb-md-25">
        <div class="row gx-xxl-6">
          <div class="col-lg-12">
            <div class="form-group mb-25">
              <label class="d-block fs-14 text-black mb-2"
                >Nombre del Producto</label
              >
              <input
                v-model="product.name"
                type="text"
                class="w-100 d-block shadow-none fs-14 bg-white rounded-1 text-title"
                placeholder="Ingresar Nombre del Producto"
                required
              />
              <span class="text-red fs-12 d-none"
                >¡Este campo es requerido!</span
              >
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group mb-25">
              <label class="d-block fs-14 text-black mb-2"
                >Código Producto</label
              >
              <input
                v-model="product.code"
                type="text"
                class="w-100 d-block shadow-none fs-14 bg-white rounded-1 text-title"
                placeholder="Escanear Código de Barra"
                required
              />
              <span class="text-parageaph d-block w-100 fs-12 mt-1 d-none"
                >Escanea tu código de barra</span
              >
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group mb-25">
              <label class="d-block fs-14 text-black mb-2">Categoría</label>
              <input
                v-model="product.category"
                type="text"
                class="w-100 d-block shadow-none fs-14 bg-white rounded-1 text-title"
                placeholder="Ingresa la Categoría"
                required
              />
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group mb-25">
              <label class="d-block fs-14 text-black mb-2">Marca</label>
              <input
                v-model="product.brand"
                type="text"
                class="w-100 d-block shadow-none fs-14 bg-white rounded-1 text-title"
                placeholder="Ingresar Marca del Producto"
                required
              />
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group mb-25">
              <label class="d-block fs-14 text-black mb-2"
                >Precio de Costo</label
              >
              <input
                :value="formattedCost"
                @input="onInput('cost', $event)"
                @focus="unformatInput('cost')"
                @blur="formatInput('cost')"
                type="text"
                class="w-100 d-block shadow-none fs-14 bg-white rounded-1 text-title"
                placeholder="Ingresar Costo del Producto"
                required
              />
              <span class="text-red fs-12 d-none"
                >¡Este campo es requerido!</span
              >
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group mb-25">
              <label class="d-block fs-14 text-black mb-2"
                >Precio de Venta</label
              >
              <input
                :value="formattedPrice"
                @input="onInput('price', $event)"
                @focus="unformatInput('price')"
                @blur="formatInput('price')"
                type="text"
                class="w-100 d-block shadow-none fs-14 bg-white rounded-1 text-title"
                placeholder="Ingresar Precio de Venta"
                required
              />
              <span class="text-red fs-12 d-none"
                >¡Este campo es requerido!</span
              >
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group mb-25">
              <label class="d-block fs-14 text-black mb-2">SKU</label>
              <input
                v-model="product.sku"
                type="text"
                class="w-100 d-block shadow-none fs-14 bg-white rounded-1 text-title"
                placeholder="Ingresar SKU"
                required
              />
            </div>
          </div>

          <div class="col-lg-12">
            <div class="form-group mb-25">
              <label class="d-block fs-14 text-black mb-2">Descripción</label>
              <textarea
                v-model="product.description"
                cols="30"
                rows="10"
                placeholder="Agrega la descripción del producto."
                class="d-block w-100 bg-white border-0 rounded-1 resize-none fs-14 text-title"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xxl-3 col-xl-4 col-lg-4">
        <AddImages
          :initialImage="product.image"
          @image-selected="onImageSelected"
        />
        <div class="col-12 mt-5">
          <button
            class="btn style-one transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16"
            type="submit"
            :disabled="!isFormValid"
          >
            {{ isEditView ? "Guardar Cambios" : "Crear Producto" }}
          </button>
          <BackButton>Volver</BackButton>
        </div>
      </div>
    </div>
    <SuccessModal
      :title="'El producto fue creado exitosamente'"
      :message="'Serás redirigido al listado de productos'"
      :show="showSuccessModal"
      @close="showSuccessModal = false"
    />
    <ErrorModal
      :title="errorTitle"
      :messages="errorMessage"
      :show="showErrorModal"
      @close="showErrorModal = false"
    />
  </form>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from "vue";
import axios from "axios";
import { useRouter, useRoute } from "vue-router";
import AddImages from "./AddImages.vue";
import SuccessModal from "../../Common/SuccessModal.vue";
import ErrorModal from "../../Common/ErrorModal.vue";
import BackButton from "../../Common/BackButton.vue";
import { unformatNumber } from "@/utils/filters";

interface Product {
  name: string;
  code: string;
  sku: string;
  category: string;
  brand: string;
  description: string;
  cost: string;
  price: string;
  stock: number;
  stockAlert: number;
  image: string;
}

export default defineComponent({
  name: "CreateProducts",
  components: {
    AddImages,
    SuccessModal,
    ErrorModal,
    BackButton,
  },
  props: {
    isEditView: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const product = ref<Product>({
      name: "",
      code: "",
      sku: "",
      category: "",
      brand: "",
      description: "",
      cost: "",
      price: "",
      stock: 0,
      stockAlert: 0,
      image: "",
    });
    const formattedCost = ref<string>("");
    const formattedPrice = ref<string>("");
    const selectedImage = ref<File | null>(null);
    const showSuccessModal = ref(false);
    const showErrorModal = ref(false);
    const errorMessage = ref<string>("");
    const errorTitle = ref("");

    const onImageSelected = (image: File) => {
      selectedImage.value = image;
    };

    const fetchProduct = async () => {
      if (props.isEditView && route.params.id) {
        try {
          const response = await axios.get(
            `${process.env.VUE_APP_AYM_BFF}/v1/product?page=1&limit=1&param=id&value=${route.params.id}`
          );
          product.value = response.data[0];
          formattedCost.value = Number(product.value.cost).toLocaleString(
            "es-CL"
          );
          formattedPrice.value = Number(product.value.price).toLocaleString(
            "es-CL"
          );
        } catch (error) {
          console.error("Error fetching product:", error);
        }
      }
    };

    const formatInput = (field: keyof Product) => {
      if (field === "cost") {
        if (product.value.cost) {
          formattedCost.value = Number(product.value.cost).toLocaleString(
            "es-CL"
          );
        }
      } else if (field === "price") {
        if (product.value.price) {
          formattedPrice.value = Number(product.value.price).toLocaleString(
            "es-CL"
          );
        }
      }
    };

    const unformatInput = (field: keyof Product) => {
      if (field === "cost") {
        formattedCost.value = product.value.cost.toString();
      } else if (field === "price") {
        formattedPrice.value = product.value.price.toString();
      }
    };

    const onInput = (field: keyof Product, event: Event) => {
      const target = event.target as HTMLInputElement;
      target.value = target.value.replace(/[^0-9]/g, "");
      if (target.value.startsWith("0") && target.value.length > 1) {
        target.value = target.value.replace(/^0+/, "");
      }
      product.value[field] = target.value as never;
      if (field === "cost") {
        formattedCost.value = target.value;
      } else if (field === "price") {
        formattedPrice.value = target.value;
      }
    };

    const isFormValid = computed(() => {
      return (
        product.value.name &&
        product.value.code &&
        product.value.category &&
        product.value.brand &&
        product.value.cost &&
        product.value.price &&
        product.value.sku &&
        product.value.description
      );
    });

    const submitForm = async () => {
      product.value.cost = unformatNumber(formattedCost.value).toString();
      product.value.price = unformatNumber(formattedPrice.value).toString();

      const formData = new FormData();
      formData.append("name", product.value.name);
      formData.append("code", product.value.code);
      formData.append("sku", product.value.sku);
      formData.append("category", product.value.category);
      formData.append("brand", product.value.brand);
      formData.append("description", product.value.description);
      formData.append("cost", product.value.cost);
      formData.append("price", product.value.price);
      formData.append("stock", product.value.stock.toString());
      formData.append("stockAlert", product.value.stockAlert.toString());

      if (selectedImage.value) {
        formData.append("file", selectedImage.value);
      }

      try {
        let response;
        if (props.isEditView && route.params.id) {
          response = await axios.put(
            `${process.env.VUE_APP_AYM_BFF}/v1/product/${route.params.id}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
        } else {
          response = await axios.post(
            `${process.env.VUE_APP_AYM_BFF}/v1/product`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
        }
        showSuccessModal.value = true;
        setTimeout(() => {
          showSuccessModal.value = false;
          router.push("/inventory");
        }, 2000);
      } catch (error: any) {
        if (error.response.data.message) {
          errorMessage.value = error.response.data.message;
        } else {
          errorMessage.value =
            "Inténtalo más tarde, si el problema persiste comunícate con soporte.";
        }
        errorTitle.value = "Ocurrió un error al crear el producto";
        showErrorModal.value = true;
      }
    };

    onMounted(fetchProduct);

    return {
      product,
      formattedCost,
      formattedPrice,
      submitForm,
      onImageSelected,
      showSuccessModal,
      showErrorModal,
      errorMessage,
      errorTitle,
      formatInput,
      unformatInput,
      onInput,
      isFormValid,
    };
  },
});
</script>
