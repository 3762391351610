<template>
  <div class="card border-0 shadow-none rounded-1 mb-25">
    <div class="card-body p-xl-40">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th scope="col" class="text-title fw-normal fs-14 pt-0">
                IMAGEN
              </th>
              <th scope="col" class="text-title fw-normal fs-14 pt-0">
                CÓDIGO
              </th>
              <th scope="col" class="text-title fw-normal fs-14 pt-0">SKU</th>
              <th scope="col" class="text-title fw-normal fs-14 pt-0">
                NOMBRE
              </th>
              <th scope="col" class="text-title fw-normal fs-14 pt-0">
                CATEGORÍA
              </th>
              <th scope="col" class="text-title fw-normal fs-14 pt-0">MARCA</th>
              <th scope="col" class="text-title fw-normal fs-14 pt-0">
                PRECIO
              </th>
              <th scope="col" class="text-title fw-normal fs-14 pt-0">STOCK</th>
              <th scope="col" class="text-title fw-normal fs-14 pt-0 pe-0">
                ACCIONES
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="product in products" :key="product._id">
              <td class="shadow-none fw-normal text-black title ps-0">
                <img :src="product.image" alt="Image" class="sizeImage" />
              </td>
              <td class="shadow-none lh-1 fs-14 fw-normal text-paragraph">
                {{ product.code }}
              </td>
              <td class="shadow-none lh-1 fs-14 fw-normal text-paragraph">
                {{ product.sku }}
              </td>
              <td class="shadow-none lh-1 fs-14 fw-normal text-paragraph">
                {{ product.name }}
              </td>
              <td class="shadow-none lh-1 fs-14 fw-normal text-paragraph">
                {{ product.category }}
              </td>
              <td class="shadow-none lh-1 fs-14 fw-normal text-paragraph">
                {{ product.brand }}
              </td>
              <td class="shadow-none lh-1 fs-14 fw-normal text-paragraph">
                ${{ $filters.formatNumber(product.price) }}
              </td>
              <td
                class="shadow-none lh-1 fs-14 fw-normal text-paragraph stock-field"
              >
                <b>{{ $filters.formatNumber(product.stock) }}</b>
              </td>
              <td class="shadow-none lh-1 text-end pe-0">
                <div class="button-group d-flex flex-wrap align-items-center">
                  <router-link
                    :to="{
                      name: 'ProductDetailsPage',
                      params: { id: product._id },
                    }"
                    class=""
                    title="View"
                  >
                    <img src="../../../assets/img/icons/eye.svg" alt="Image" />
                  </router-link>
                  <router-link
                    :to="{
                      name: 'EditProductsPage',
                      params: { id: product._id },
                    }"
                    class=""
                    title="Edit"
                  >
                    <img src="../../../assets/img/icons/edit.svg" alt="Image" />
                  </router-link>
                  <a
                    class="delete-btn"
                    role="button"
                    @click="openStockModal('decrease', product)"
                  >
                    <img
                      src="../../../assets/img/icons/subtract_icon.svg"
                      style="height: 20px"
                      alt="Image"
                    />
                  </a>
                  <a
                    class="delete-btn"
                    role="button"
                    @click="openStockModal('increase', product)"
                  >
                    <img
                      src="../../../assets/img/icons/add_icon.svg"
                      style="height: 20px"
                      alt="Image"
                    />
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
          <div class="not-found-products" v-show="products.length === 0">
            No se encontraron productos.
          </div>
        </table>
      </div>
    </div>
  </div>
  <div class="row pb-45 align-items-center">
    <div class="col-sm-6">
      <div
        class="d-flex flex-wrap align-items-center justify-content-center justify-content-sm-start page-unit"
      >
        <span class="fs-13"
          >Productos por página <b>{{ pageSize }}</b></span
        >
      </div>
    </div>
    <div class="col-sm-6 text-sm-end text-center">
      <ul class="page-nav list-style">
        <li>
          <a
            href="#"
            @click.prevent="changePage(currentPage - 1)"
            :class="{ disabled: currentPage === 1 }"
          >
            <img
              src="../../../assets/img/icons/left-arrow-purple.svg"
              alt="Image"
            />
          </a>
        </li>
        <li v-for="page in totalPages" :key="page">
          <a
            href="#"
            :class="{ active: page === currentPage }"
            @click.prevent="changePage(page)"
            >{{ page }}</a
          >
        </li>
        <li>
          <a
            href="#"
            @click.prevent="changePage(currentPage + 1)"
            :class="{ disabled: currentPage === totalPages }"
          >
            <img
              src="../../../assets/img/icons/right-arrow-purple.svg"
              alt="Image"
            />
          </a>
        </li>
      </ul>
    </div>
  </div>
  <StockModal
    :show="showStockModal"
    @close="showStockModal = false"
    @adjust-stock="adjustStock"
    :action="stockAction"
    :product="selectedProduct"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import StockModal from "../ProductStockHistory/StockModal.vue";
import { useToast } from "vue-toastification";

export default defineComponent({
  name: "ProductsList",
  components: {
    StockModal,
  },
  props: {
    barcode: {
      type: String,
      default: "",
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      products: [],
      currentPage: 1,
      pageSize: 10,
      totalPages: 0,
      showStockModal: false,
      stockAction: "",
      selectedProduct: null as any | null,
    };
  },
  watch: {
    barcode() {
      this.fetchProducts();
    },
    filters: {
      handler() {
        this.fetchProducts();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchProducts();
  },
  methods: {
    async fetchProducts() {
      const params: any = {
        page: this.currentPage,
        limit: this.pageSize,
        code: this.barcode,
        ...this.filters,
      };

      console.log(params);

      const queryString = Object.keys(params)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
        )
        .join("&");

      try {
        const response = await axios.get(
          `${process.env.VUE_APP_AYM_BFF}/v1/products?${queryString}`
        );
        console.log(response);
        this.products = response.data.products;
        this.totalPages = Math.ceil(response.data.total / this.pageSize);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },
    changePage(page: number) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
        this.fetchProducts();
      }
    },
    openStockModal(action: string, product: any) {
      this.stockAction = action;
      this.selectedProduct = product;
      this.showStockModal = true;
    },
    async adjustStock(quantity: number) {
      const toast = useToast();
      if (this.selectedProduct) {
        const data = {
          productId: this.selectedProduct._id,
          quantity,
          action: this.stockAction,
          initialStock: this.selectedProduct.stock,
          finalStock:
            this.stockAction === "increase"
              ? this.selectedProduct.stock + quantity
              : this.selectedProduct.stock - quantity,
          date: new Date(),
        };

        try {
          const response = await axios.post(
            `${process.env.VUE_APP_AYM_BFF}/v1/product/adjust-stock`,
            data,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          console.log(response.data);
          this.fetchProducts();
          toast.success("El stock se ajusto correctamente");
          this.showStockModal = false;
        } catch (error) {
          console.error(error);
          toast.error(
            "Ocurrió un error al actualizar el stock, intenta nuevamente"
          );
          this.showStockModal = false;
        }
      }
    },
  },
});
</script>

<style>
.sizeImage {
  height: 60px;
  width: 60px;
  margin-left: 15px;
}
.stock-field {
  font-size: 18px;
}
.page-nav .disabled {
  pointer-events: none;
  opacity: 0.5;
}
.page-nav .active {
  font-weight: bold;
  color: purple;
}
.close {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}
.not-found-products {
  margin-top: 25px;
  font-size: 18px;
  font-weight: bold;
}
</style>
