<template>
  <div class="card border-0 shadow-none rounded-1 mb-25">
    <div class="card-body p-xl-40">
      <div class="table-responsive style-three">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th scope="col" class="text-title fw-normal fs-14 pt-0">FECHA</th>
              <th scope="col" class="text-title fw-normal fs-14 pt-0">
                REFERENCIA
              </th>
              <th scope="col" class="text-title fw-normal fs-14 pt-0">
                VENDEDOR
              </th>
              <th scope="col" class="text-title fw-normal fs-14 pt-0">
                CLIENTE
              </th>
              <th scope="col" class="text-title fw-normal fs-14 pt-0">
                CASA MATRIZ
              </th>
              <th scope="col" class="text-title fw-normal fs-14 pt-0">
                PRODUCTOS
              </th>
              <th scope="col" class="text-title fw-normal fs-14 pt-0">
                TOTAL GENERAL
              </th>
              <th scope="col" class="text-title fw-normal fs-14 pt-0">
                ESTADO
              </th>
              <th scope="col" class="text-title fw-normal fs-14 pt-0 pe-0">
                ACCIONES
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="quotation in quotations" :key="quotation._id">
              <td class="shadow-none fw-normal text-black title ps-0">
                <div class="d-flex align-items-center">
                  <span class="text-optional fs-14 ms-2">{{
                    $filters.formatDate(quotation.date)
                  }}</span>
                </div>
              </td>
              <td class="shadow-none lh-1 fs-14 fw-normal text-paragraph">
                {{ quotation.codReference }}
              </td>
              <td class="shadow-none lh-1 fs-14 fw-normal text-paragraph">
                {{ quotation.customer.name }}
              </td>
              <td class="shadow-none lh-1 fs-14 fw-normal text-paragraph">
                {{ quotation.client.name }}
              </td>
              <td class="shadow-none lh-1 fs-14 fw-normal text-paragraph">
                {{ $filters.translateCodeWareHouse(quotation.warehouse) }}
              </td>
              <td class="shadow-none lh-1 fs-14 fw-normal text-paragraph">
                {{ calculateTotalQuantity(quotation.products) }}
              </td>
              <td class="shadow-none lh-1 fs-14 fw-normal text-paragraph">
                ${{ $filters.formatNumber(quotation.total) }}
              </td>
              <td class="shadow-none lh-1 fs-14 fw-normal text-paragraph">
                <div class="form-group">
                  <select
                    class="bg-white border-0 rounded-1 fs-14 text-optional"
                    v-model="quotation.status"
                    @change="updateQuotationStatus(quotation)"
                  >
                    <option value="0">Pendiente</option>
                    <option value="1">Enviada</option>
                    <option value="2">Aprobada</option>
                    <option value="3">Despachada</option>
                  </select>
                </div>
              </td>
              <td class="shadow-none lh-1 text-end pe-0">
                <div class="button-group d-flex flex-wrap align-items-center">
                  <a
                    href="javascript:void(0)"
                    title="View"
                    @click="$emit('show-details', quotation)"
                  >
                    <img src="../../../assets/img/icons/eye.svg" alt="Image" />
                  </a>
                  <a
                    class="delete-btn"
                    href="javascript:void(0)"
                    @click="openDeleteModal(quotation)"
                  >
                    <img
                      src="../../../assets/img/icons/close.svg"
                      alt="Image"
                    />
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="row pb-45 align-items-center">
    <div class="col-sm-6">
      <div
        class="d-flex flex-wrap align-items-center justify-content-center justify-content-sm-start page-unit"
      >
        <span class="fs-13"
          >Productos por página <b>{{ pageSize }}</b></span
        >
      </div>
    </div>
    <div class="col-sm-6 text-sm-end text-center">
      <ul class="page-nav list-style">
        <li>
          <a href="#" @click.prevent="changePage(currentPage - 1)">
            <img
              src="../../../assets/img/icons/left-arrow-purple.svg"
              alt="Image"
            />
          </a>
        </li>
        <li v-for="page in totalPages" :key="page">
          <a
            href="#"
            :class="{ active: page === currentPage }"
            @click.prevent="changePage(page)"
            >{{ page }}</a
          >
        </li>
        <li>
          <a href="#" @click.prevent="changePage(currentPage + 1)">
            <img
              src="../../../assets/img/icons/right-arrow-purple.svg"
              alt="Image"
            />
          </a>
        </li>
      </ul>
    </div>
  </div>

  <WarningModal
    :title="'El producto será eliminado'"
    :message="'¿Estás seguro de que deseas eliminar esta cotización?'"
    :show="showDeleteModal"
    @close="showDeleteModal = false"
    @confirm="confirmDelete"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import { useToast } from "vue-toastification";
import WarningModal from "../../Common/WarningModal.vue"; // Ajusta la ruta según sea necesario

interface Customer {
  name: string;
  mail: string;
  phone: string;
}

interface Client {
  name: string;
  mail: string;
  phone: string;
  address: string;
}

interface Product {
  _id: string;
  image: string;
  name: string;
  code: string;
  sku: string;
  category: string;
  brand: string;
  description: string;
  stockAlert: number;
  cost: number;
  price: number;
  stock: number;
  __v: number;
}
interface QuotationProduct {
  _id: string;
  price: number;
  quantity: number;
}
interface Quotation {
  _id: string;
  date: string;
  time: string;
  codReference: string;
  warehouse: string;
  orderTax: number | null;
  discount: number;
  shipping: number;
  total: number | null;
  comments: string;
  status: string;
  customer: Customer;
  client: Client;
  products: QuotationProduct[];
  __v: number;
}

export default defineComponent({
  name: "QuotationList",
  components: { WarningModal },
  data() {
    return {
      quotations: [] as Quotation[],
      currentPage: 1,
      pageSize: 10,
      totalPages: 0,
      totalQuotations: 0,
      quotationToDelete: null as Quotation | null,
      showDeleteModal: false, // Asegúrate de que esto es booleano
    };
  },
  mounted() {
    this.fetchQuotations();
  },
  methods: {
    async fetchQuotations() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_AYM_BFF}/v1/quotation?page=${this.currentPage}&limit=${this.pageSize}`
        );
        console.log(response.data);
        this.totalQuotations = response.data.total;
        this.totalPages = Math.ceil(this.totalQuotations / this.pageSize);
        this.quotations = response.data.quotations;
      } catch (error) {
        console.error("Error fetching quotations", error);
      }
    },
    calculateTotal(quotation: Quotation): number {
      if (!quotation.products || quotation.products.length === 0) {
        return 0;
      }
      return quotation.products.reduce((total, product) => {
        console.log("PRODUCT", product);
        const price = product.price || 0;
        const quantity = product.quantity || 0;
        return total + price * quantity;
      }, 0);
    },
    calculateTotalQuantity(products: QuotationProduct[]) {
      return products.reduce((total, product) => {
        return total + product.quantity;
      }, 0);
    },
    getStatusText(status: string): string {
      const statusMap: { [key: string]: string } = {
        "0": "Pendiente",
        "1": "Enviado",
        "2": "Recibido",
      };
      return statusMap[status] || "Desconocido";
    },
    changePage(page: number) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
        this.fetchQuotations();
      }
    },
    openDeleteModal(quotation: Quotation) {
      this.quotationToDelete = quotation;
      this.showDeleteModal = true; // Asegúrate de que esto es booleano
    },
    async confirmDelete() {
      if (this.quotationToDelete) {
        try {
          await axios.delete(
            `${process.env.VUE_APP_AYM_BFF}/v1/quotation/${this.quotationToDelete._id}`
          );
          this.fetchQuotations();
          this.quotationToDelete = null;
          this.showDeleteModal = false; // Asegúrate de que esto es booleano
        } catch (error) {
          console.error("Error deleting quotation", error);
        }
      }
    },
    async updateQuotationStatus(quotation: Quotation) {
      const toast = useToast();
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_AYM_BFF}/v1/quotation/${quotation._id}`,
          { status: quotation.status }
        );
        console.log("Quotation status updated", response.data);
        toast.success("Estado de la cotización actualizado correctamente");
      } catch (error) {
        console.error("Error updating quotation status", error);
        toast.error("Error al actualizar el estado de la cotización");
      }
    },
  },
});
</script>
