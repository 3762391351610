import { createWebHistory, createRouter, RouteRecordRaw } from "vue-router";
import { jwtDecode } from "jwt-decode";
import CreateProductsPage from "../pages/Products/CreateProductsPage.vue";
import ProductsListPage from "../pages/Products/ProductsListPage.vue";
import EditProductsPage from "../pages/Products/EditProductsPage.vue";
import ProductDetailsPage from "../pages/Products/ProductDetailsPage.vue";
import CreateQuotationPage from "../pages/Quotations/CreateQuotationPage.vue";
import QuotationListPage from "../pages/Quotations/QuotationListPage.vue";
import EditQuotationPage from "../pages/Quotations/EditQuotationPage.vue";
import LoginPage from "../pages/Login/LoginPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/inventory",
    name: "ProductsListPage",
    component: ProductsListPage,
  },
  {
    path: "/inventory/create-product",
    name: "CreateProductsPage",
    component: CreateProductsPage,
    props: { isEditView: false },
  },
  {
    path: "/inventory/edit-product/:id",
    name: "EditProductsPage",
    component: EditProductsPage,
    props: (route) => ({ isEditView: true, id: route.params.id }),
  },
  {
    path: "/inventory/product-details/:id",
    name: "ProductDetailsPage",
    component: ProductDetailsPage,
    props: true,
  },
  {
    path: "/quotation/create-quotation",
    name: "CreateQuotationPage",
    component: CreateQuotationPage,
  },
  {
    path: "/quotation",
    name: "QuotationListPage",
    component: QuotationListPage,
  },
  {
    path: "/quotation/edit-quotation",
    name: "EditQuotationPage",
    component: EditQuotationPage,
  },
  { path: "/login", name: "LoginPage", component: LoginPage },
  {
    path: "/:catchAll(.*)",
    redirect: "/login",
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

// Función para verificar la validez del token
function isTokenValid(token: string) {
  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decoded.exp && decoded.exp > currentTime;
  } catch (error) {
    return false;
  }
}

// Guardián de navegación global
router.beforeEach((to, _, next) => {
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const token = sessionStorage.getItem("token");

  if (authRequired && (!token || !isTokenValid(token))) {
    sessionStorage.removeItem("token"); // Elimina el token inválido
    sessionStorage.removeItem("user"); // Elimina los datos del usuario
    return next("/login");
  }

  next();
});

export default router;
