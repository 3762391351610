<template>
  <footer class="footer-wrap style-two">
    <div class="row align-items-center">
      <div class="col-md-6">
        <div class="copyright-text">
          Todos los derechos reservados
          <span class="text-white text-bold"> &copy; Lubricentro AYM</span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "MainFooter",
};
</script>

<style lang="scss" scoped>
.footer-wrap {
  background-color: #EAA00A;
  color: #fff;
  padding: {
    top: 26px;
    bottom: 26px;
  }
  .copyright-text {
    color: #fff;
    a {
      &:hover {
        color: var(--secondaryColor) !important;
      }
    }
  }
  &.style-two {
    margin: 0 -12px;
    padding-left: 12px;
    padding-right: 12px;
  }
}
@media only screen and (max-width: 767px) {
  .footer-wrap {
    padding: {
      top: 18px;
      bottom: 18px;
    }
    .copyright-text {
      margin-bottom: 8px;
    }
    .copyright-text,
    p {
      text-align: center;
      font-size: 14px;
    }
  }
}

@media only screen and (min-width: 1400px) {
  .footer-wrap {
    &.style-two {
      margin: 0 -40px;
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}
</style>
