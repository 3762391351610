<template>
  <div v-if="show" class="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ action === "increase" ? "Aumentar" : "Disminuir" }} Stock
          </h5>
          <button type="button" class="close" @click="close">&times;</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="openConfirmModal">
            <div class="form">
              <div class="form-group">
                <label for="currentStock">Stock Actual</label>
                <input
                  type="number"
                  class="form-control"
                  id="currentStock"
                  :value="currentStock"
                  disabled
                />
              </div>
              <div class="form-group quantity-input">
                <label for="quantity">Cantidad</label>
                <div class="input-group">
                  <button @click.prevent="decrease" :disabled="quantity <= 1">
                    -
                  </button>
                  <input
                    v-model="quantity"
                    type="number"
                    class="form-control"
                    id="quantity"
                    min="1"
                    v-focus
                    @input="validateQuantity"
                  />
                  <button @click.prevent="increase">+</button>
                </div>
              </div>
              <div class="form-group">
                <label for="newStock">Nuevo Stock</label>
                <input
                  type="number"
                  class="form-control"
                  id="newStock"
                  :value="newStock"
                  disabled
                />
              </div>
            </div>
            <div class="action-btn">
              <button
                type="submit"
                class="btn btn-primary"
                :disabled="isConfirmDisabled"
              >
                Confirmar
              </button>
              <button
                type="button"
                class="btn btn-primary close-btn"
                @click="close"
              >
                Cerrar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <ConfirmationModal
      :show="showConfirmModal"
      title="Confirmación"
      message="¿Está seguro de que desea ajustar el stock?"
      @close="closeConfirmModal"
      @confirm="submit"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from "vue";
import ConfirmationModal from "../../Common/WarningModal.vue";
import axios from "axios";
import { useToast } from "vue-toastification";

export default defineComponent({
  name: "StockModal",
  components: {
    ConfirmationModal,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  emits: ["close", "adjust-stock"],
  setup(props, { emit }) {
    const quantity = ref<string>("");

    const close = () => {
      emit("close");
    };

    const submit = async () => {
      const toast = useToast();
      const adjustedQuantity = parseInt(quantity.value) || 0;
      const initialStock = props.product.stock;
      const finalStock =
        props.action === "increase"
          ? initialStock + adjustedQuantity
          : initialStock - adjustedQuantity;

      const data = {
        productId: props.product._id,
        quantity: adjustedQuantity,
        action: props.action,
        initialStock,
        finalStock,
        date: new Date(),
      };

      try {
        await axios.post(
          `${process.env.VUE_APP_AYM_BFF}/v1/stock-history`,
          data
        );
        toast.success("El historial de stock se guardó correctamente");
        emit("adjust-stock", adjustedQuantity);
        close();
      } catch (error) {
        toast.error(
          "Ocurrió un error al guardar el historial de stock, intenta nuevamente"
        );
        console.error(error);
      }

      closeConfirmModal();
    };

    const openConfirmModal = () => {
      showConfirmModal.value = true;
    };

    const closeConfirmModal = () => {
      showConfirmModal.value = false;
    };

    const showConfirmModal = ref(false);

    const currentStock = computed(() => props.product.stock);
    const newStock = computed(() => {
      const qty = parseInt(quantity.value) || 0;
      return props.action === "increase"
        ? currentStock.value + qty
        : currentStock.value - qty;
    });

    const increase = () => {
      const qty = parseInt(quantity.value) || 0;
      quantity.value = (qty + 1).toString();
    };

    const decrease = () => {
      const qty = parseInt(quantity.value) || 0;
      if (qty > 0) {
        quantity.value = (qty - 1).toString();
      }
    };

    const validateQuantity = () => {
      const qty = parseInt(quantity.value);
      if (isNaN(qty) || qty < 0) {
        quantity.value = "";
      }
    };

    const isConfirmDisabled = computed(() => {
      const qty = parseInt(quantity.value);
      return isNaN(qty) || qty <= 0;
    });

    watch(
      () => props.show,
      (newVal) => {
        if (newVal) {
          quantity.value = "";
        }
      }
    );

    return {
      quantity,
      currentStock,
      newStock,
      close,
      submit,
      increase,
      decrease,
      validateQuantity,
      showConfirmModal,
      openConfirmModal,
      closeConfirmModal,
      isConfirmDisabled,
    };
  },
  directives: {
    focus: {
      mounted(el) {
        el.focus();
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.modal {
  display: block;
  background: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
  max-width: 500px;
  margin: 100px auto;
}

.modal-content {
  padding: 20px;

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: space-around;
  }
}

.close {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.action-btn {
  display: flex;
  justify-content: center;
  margin-top: 30px;

  .close-btn {
    margin-left: 10px;
  }
}
.form {
  display: flex;
  .form-group {
    margin-bottom: 15px;
    text-align: center;

    .form-control {
      text-align: center;
    }

    &.quantity-input {
      margin: 0 10px;
      .input-group {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        margin-left: 0;
        button {
          background-color: #007bff;
          color: white;
          border: none;
          width: 30px;
          height: 30px;
          cursor: pointer;

          &:disabled {
            background-color: #cccccc;
            cursor: not-allowed;
          }
        }

        input {
          text-align: center;
          width: 60px;
          margin: 0 5px;
        }
      }
    }
  }
  #quantity {
    &:focus {
      border: none;
    }
    &:not(:focus) {
      border: none;
    }
  }
}
</style>
