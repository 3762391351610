<template>
  <div class="row mb-40">
    <div class="col-xxl-10 col-lg-8 pe-xxl-8">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group mb-25">
            <label class="d-block fs-14 text-black mb-2">IVA</label>
            <input
              type="number"
              class="w-100 d-block shadow-none fs-14 bg-white rounded-1 text-title"
              :value="taxRate"
              readonly
            />
            <span
              class="percent-sign position-absolute rounded-1 text-center d-flex flex-column justify-content-center"
            >
              %
            </span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group mb-25">
            <label class="d-block fs-14 text-black mb-2">Costo de Envío</label>
            <input
              type="number"
              class="w-100 d-block shadow-none fs-14 bg-white rounded-1 text-title"
              v-model.number="localShippingCost"
            />
            <span
              class="percent-sign position-absolute rounded-1 text-center d-flex flex-column justify-content-center fw-semibold fs-16"
            >
              $
            </span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group mb-25">
            <label class="d-block fs-14 text-black mb-2">Descuento</label>
            <input
              type="number"
              class="w-100 d-block shadow-none fs-14 bg-white rounded-1 text-title"
              v-model.number="localDiscount"
            />
            <span
              class="percent-sign position-absolute rounded-1 text-center d-flex flex-column justify-content-center fw-semibold fs-16"
            >
              $
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl-2 col-lg-4">
      <div class="card border-0 rounded-1 w-xxl-5 pt-12 pb-12 mb-md-25">
        <table class="table style-two">
          <tbody>
            <tr>
              <th scope="row" class="fs-14 text-title lh-1 ls-1 fw-normal">
                SUBTOTAL GENERAL
              </th>
              <td class="fs-14 fw-semibold lh-1 text-optional text-end">
                ${{ $filters.formatNumber(calculateSubtotal()) }}
              </td>
            </tr>
            <tr>
              <th scope="row" class="fs-14 text-title lh-1 ls-1 fw-normal">
                IVA
              </th>
              <td class="fs-14 fw-semibold lh-1 text-optional text-end">
                ${{ $filters.formatNumber(calculateTax()) }}
              </td>
            </tr>
            <tr>
              <th scope="row" class="fs-14 text-title lh-1 ls-1 fw-normal">
                ENVÍO
              </th>
              <td class="fs-14 fw-semibold lh-1 text-optional text-end">
                ${{ $filters.formatNumber(localShippingCost) }}
              </td>
            </tr>
            <tr>
              <th scope="row" class="fs-14 text-title lh-1 ls-1 fw-normal">
                DESCUENTO
              </th>
              <td class="fs-14 fw-semibold lh-1 text-optional text-end">
                ${{ $filters.formatNumber(localDiscount) }}
              </td>
            </tr>
            <tr>
              <th scope="row" class="fs-14 text-title lh-1 ls-1 fw-semibold">
                TOTAL GENERAL
              </th>
              <td class="fs-14 fw-bold lh-1 text-purple text-end">
                ${{ $filters.formatNumber(calculateTotal()) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group mb-25">
        <label class="d-block fs-14 text-black mb-2">Nota</label>
        <textarea
          cols="30"
          rows="10"
          placeholder="Añadir una nota"
          class="d-block w-100 bg-white border-0 rounded-1 resize-none fs-14 text-title"
          v-model="localNote"
        ></textarea>
      </div>
    </div>
    <div class="col-xl-12">
      <button
        class="btn style-one d-inline-block transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 mb-20"
        type="submit"
        :disabled="!isFormValid"
        @click="handleSubmit"
      >
        {{ isEditView ? "Editar" : "Crear" }} Cotización
      </button>
      <button class="mb-20 btn btn-danger" @click="handleCancel">
        Cancelar
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watch } from "vue";

interface Product {
  name: string;
  code: string;
  price: number;
  quantity: number;
  brand: string;
  stock: number;
}

export default defineComponent({
  name: "SubmitQuotation",
  props: {
    products: {
      type: Array as PropType<Product[]>,
      required: true,
    },
    taxRate: {
      type: Number,
      required: true,
    },
    discount: {
      type: Number,
      required: true,
    },
    shippingCost: {
      type: Number,
      required: true,
    },
    note: {
      type: String,
      required: true,
    },
    isEditView: {
      type: Boolean,
      default: false,
    },
    isFormValid: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const localDiscount = ref(props.discount);
    const localShippingCost = ref(props.shippingCost);
    const localNote = ref(props.note);

    watch(localDiscount, (newValue) => {
      emit("update:discount", newValue);
    });

    watch(localShippingCost, (newValue) => {
      emit("update:shipping-cost", newValue);
    });

    watch(localNote, (newValue) => {
      emit("update:note", newValue);
    });

    const calculateSubtotal = () => {
      const subtotal = props.products.reduce((total, product) => {
        return product && product.price
          ? total + product.price * product.quantity
          : total;
      }, 0);
      emit("update-subtotal", subtotal);
      return subtotal;
    };

    const calculateTax = () => {
      const tax = Math.round((calculateSubtotal() * props.taxRate) / 100);
      emit("update-tax", tax);
      return tax;
    };

    const calculateTotal = () => {
      const total =
        calculateSubtotal() +
        calculateTax() -
        localDiscount.value +
        localShippingCost.value;
      emit("update-total", total);
      return total;
    };

    watch(
      () => [
        props.products,
        props.taxRate,
        localDiscount.value,
        localShippingCost.value,
      ],
      () => {
        calculateSubtotal();
        calculateTax();
        calculateTotal();
      },
      { deep: true }
    );

    const handleSubmit = () => {
      emit("submit");
    };
    const handleCancel = () => {
      emit("cancel");
    };

    return {
      localDiscount,
      localShippingCost,
      localNote,
      calculateSubtotal,
      calculateTax,
      calculateTotal,
      handleSubmit,
      handleCancel,
    };
  },
});
</script>

<style scoped>
.btn.style-one {
  margin-right: 20px;
}
</style>
