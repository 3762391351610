<template>
  <div class="form-group w-xxl-5">
    <label class="d-block fs-14 text-black mb-2">Agrega una imagen</label>
    <div class="card upload-gallery border-0 rounded-1 p-xxl-30 p-4">
      <div class="file-upload text-center position-relative mb-20">
        <img src="../../../assets/img/icons/upload-files.svg" alt="Image" />
        <span class="d-block text-optional fs-14 mt-30">
          Suelta la imagen aquí o
          <span class="text_secondary fw-semibold position-relative">
            selecciona una
          </span>
        </span>
        <input
          type="file"
          class="d-block w-100 shadow-none border-0 position-absolute start-0 end-0 top-0 bottom-0 z-1 opacity-0"
          @change="onFileChange"
        />
      </div>
    </div>
    <div v-if="imageUrl" class="mt-3" style="width: 100px">
      <img :src="imageUrl" alt="Image" class="img-thumbnail" />
    </div>
    <div v-if="errorMessage" class="mt-3 text-danger">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: "AddImages",
  props: {
    initialImage: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      imageUrl: this.initialImage,
      errorMessage: "",
    };
  },
  watch: {
    initialImage(newImage) {
      this.imageUrl = newImage;
    },
  },
  methods: {
    onFileChange(event) {
      const file = event.target.files[0];
      const maxSizeInMB = 2; // Tamaño máximo en MB
      const allowedTypes = ["image/jpeg", "image/png", "image/gif"];

      this.errorMessage = "";

      if (!allowedTypes.includes(file.type)) {
        this.errorMessage =
          "El tipo de archivo no es permitido. Solo se permiten archivos JPEG, PNG y GIF.";
        this.imageUrl = null;
        this.$emit("image-selected", null);
        return;
      }

      const sizeInMB = file.size / (1024 * 1024);
      if (sizeInMB > maxSizeInMB) {
        this.errorMessage = `El tamaño del archivo es demasiado grande. El tamaño máximo permitido es ${maxSizeInMB} MB.`;
        this.imageUrl = null;
        this.$emit("image-selected", null);
        return;
      }

      this.imageUrl = URL.createObjectURL(file);
      this.$emit("image-selected", file);
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-gallery {
  .file-upload {
    padding: 40px 20px;
    border: 1.5px dashed #a8acb2;
    text-align: center;
    border-radius: 4px;
    input {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
}

@media only screen and (min-width: 1400px) {
  .upload-gallery {
    .file-upload {
      padding: 40px 75px 35px;
    }
  }
}
</style>
