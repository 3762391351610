<template>
  <div class="login-wrapper d-flex flex-wrap overflow-hidden">
    <div class="login-bg"></div>
    <div class="login-form-wrap">
      <div class="login-form">
        <router-link to="/login" class="logo">
          <img
            src="../../assets/img/logo-original.svg"
            alt="Image"
            class="d-block mx-auto"
          />
        </router-link>
        <form @submit.prevent="login">
          <div class="form-group mb-15">
            <label class="d-block fs-14 text-black mb-2"> Email </label>
            <input
              v-model="email"
              type="email"
              class="w-100 h-55 bg_ash border-0 rounded-1 fs-14 text-title"
              placeholder="correo@ejemplo.com"
            />
          </div>
          <div class="form-group mb-20 position-relative">
            <label class="d-block fs-14 text-black mb-2">Contraseña</label>
            <input
              v-model="password"
              :type="showPassword ? 'text' : 'password'"
              placeholder="Ingresar Contraseña"
              class="w-100 h-55 bg_ash border-0 rounded-1 fs-14 text-black"
            />
            <span
              id="toggler"
              class="position-absolute"
              @click="togglePassword"
            >
              <img
                src="../../assets/img/icons/eye-close.svg"
                alt="Toggle visibility"
              />
            </span>
          </div>
          <button class="btn style-one w-100 d-block mt-4">
            Iniciar Sesión
          </button>
          <p v-if="errorMessage" class="text-danger mt-2">{{ errorMessage }}</p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "LoginPage",
  data() {
    return {
      email: "",
      password: "",
      errorMessage: "",
      showPassword: false,
    };
  },
  methods: {
    login() {
      const data = JSON.stringify({
        email: this.email,
        password: this.password,
      });

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.VUE_APP_AYM_BFF}/auth/login`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          const token = response.data.access_token;
          // Almacenar el token en la sesión
          sessionStorage.setItem("token", token);
          const userData = this.parseJwt(token);
          // Almacenar datos del usuario en la sesión
          sessionStorage.setItem("user", JSON.stringify(userData));
          // Redirigir a la ruta de inventario
          this.$router.push("/inventory");
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.errorMessage = error.response.data.message;
          } else {
            this.errorMessage = "Error de conexión. Intente nuevamente.";
          }
        });
    },
    parseJwt(token) {
      try {
        return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
        return null;
      }
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style lang="scss" scoped>
.login-wrapper {
  .login-bg {
    background-image: url(../../assets/img/portada.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
  }
  .login-form-wrap {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    background-color: var(--whiteColor);
    padding: 0 50px;
    .logo {
      img {
        height: 15vh;
      }
      display: block;
      margin-bottom: 20px;
      border-bottom: 1px solid #eef3fa;
      padding-bottom: 25px;
    }
    .login-form {
      border: 1px solid var(--ashColor);
      padding: 25px;
    }
    h5 {
      font-weight: 500;
      border-bottom: 1px solid #eef3fa;
      text-align: center;
      padding-bottom: 20px;
      margin: 0 0 20px;
    }
  }

  .login-credential-item {
    border-bottom: 1px solid #eef3fa;
  }
}
.form-group {
  #toggler {
    right: 20px;
    top: 65px;
    transform: translateY(-50%);
    cursor: pointer;
  }
}

@media only screen and (max-width: 991px) {
  .login-wrapper {
    padding: 50px 0;
    .login-bg {
      width: 61%;
    }
    .login-form-wrap {
      width: 80%;
      height: 39%;
      padding: 0 12px;
      margin: 0 auto;
      .login-form {
        padding: 20px 10px;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .login-wrapper {
    height: 100vh;
    .login-bg {
      height: 100vh;
      width: 61%;
    }
    .login-form-wrap {
      width: 39%;
    }
  }
}

@media only screen and (min-width: 1920px) {
  .login-wrapper {
    .login-bg {
      width: 61%;
    }
    .login-form-wrap {
      width: 39%;
      padding: 0 100px;
      .logo {
        margin-bottom: 30px;
        padding-bottom: 45px;
      }
      .login-form {
        padding: 25px 35px;
      }
      h5 {
        padding-bottom: 20px;
        margin: 0 0 20px;
      }
    }
  }
}
</style>
