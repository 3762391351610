import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-counter style-two" }
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "minusBtn",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.increment && _ctx.increment(...args)))
    }),
    _createElementVNode("input", {
      type: "text",
      size: "25",
      value: _ctx.quantity,
      class: "count",
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
    }, null, 40, _hoisted_2),
    _createElementVNode("button", {
      class: "plusBtn",
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.decrement && _ctx.decrement(...args)))
    })
  ]))
}