import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WarningModal = _resolveComponent("WarningModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      class: "btn style-six",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openBackModal && _ctx.openBackModal(...args)), ["prevent"]))
    }, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode("Volver")
      ], true)
    ]),
    _createVNode(_component_WarningModal, {
      title: 'Confirmar acción',
      message: '¿Estás seguro de que deseas salir de esta vista? Se perderán todos los cambios no guardados.',
      show: _ctx.showBackModal,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showBackModal = false)),
      onConfirm: _ctx.confirmBack
    }, null, 8, ["message", "show", "onConfirm"])
  ], 64))
}