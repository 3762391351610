<template>
  <div v-if="show" class="modal">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <img
        src="https://climadigitalstorage.blob.core.windows.net/lubricentroaym/error-2.png"
        class="sizeImage"
      />
      <p class="mb-0">
        <b>{{ title }}</b>
      </p>
      <p v-for="message in messages" v-bind:key="message" class="mb-0 pb-0">
        <small>{{ message }}</small>
      </p>
      <button class="btn btn-danger mt-3" @click="closeModal">Cerrar</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    title: String,
    messages: [String],
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.modal {
  display: flex;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}
.modal-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: 400px;
  height: 300px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.close {
  position: absolute;
  top: 10px;
  right: 20px;
  color: #aaa;
  font-size: 24px;
  cursor: pointer;
}
.sizeImage {
  height: 80px;
  width: 80px;
  margin-bottom: 30px;
}
</style>
