<template>
  <div class="v-counter style-two">
    <button class="minusBtn" @click="increment"></button>
    <input
      type="text"
      size="25"
      :value="quantity"
      class="count"
      @input="onInput"
    />
    <button class="plusBtn" @click="decrement"></button>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "QuantityCounter",
  props: {
    modelValue: {
      type: Number,
      default: 1,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const quantity = ref(props.modelValue);

    const increment = () => {
      quantity.value++;
      emit("update:modelValue", quantity.value);
    };

    const decrement = () => {
      if (quantity.value > 1) {
        quantity.value--;
        emit("update:modelValue", quantity.value);
      }
    };

    const onInput = (event: Event) => {
      const input = event.target as HTMLInputElement;
      const value = parseInt(input.value, 10);
      if (!isNaN(value) && value > 0) {
        quantity.value = value;
        emit("update:modelValue", quantity.value);
      }
    };

    watch(
      () => props.modelValue,
      (newVal) => {
        quantity.value = newVal;
      }
    );

    return {
      quantity,
      increment,
      decrement,
      onInput,
    };
  },
});
</script>
