<template>
  <div class="row gx-0 mb-30">
    <div class="col-md-6">
      <div
        class="filter-left d-flex align-items-center flex-wrap"
        v-if="btnLink !== '/quotation/create-quotation'"
      >
        <button
          type="button"
          class="btn style-two"
          data-bs-toggle="modal"
          data-bs-target="#filterModal"
        >
          Filtros <img src="../../assets/img/icons/filter.svg" alt="Image" />
        </button>
        <button type="button" class="btn btn-clear" @click="handleClick">
          <img src="../../assets/img/icons/close.svg" alt="Image" />
        </button>
        <form
          action="#"
          class="search-area position-relative w-sm-100"
          @submit.prevent
        >
          <input
            type="text"
            v-model="barcode"
            @input="onBarcodeChange"
            placeholder="Escanear / Buscar por código de barra"
            class="w-100 h-55 bg_ash border-0 rounded-1 fs-14 text-black bg-white"
          />
          <button
            type="submit"
            class="bg-transparent border-0 position-absolute top-0 end-0 h-100 pt-0 py-0 px-2"
          >
            <img src="../../assets/img/icons/search.svg" alt="Image" />
          </button>
        </form>
      </div>
    </div>
    <div class="col-md-6">
      <div
        class="filter-right d-flex align-items-center justify-content-md-end flex-wrap"
      >
        <router-link :to="btnLink" class="btn style-one">
          Crear {{ btnText }}
          <img src="../../assets/img/icons/add-circle.svg" alt="Image" />
        </router-link>
      </div>
    </div>
  </div>
  <FilterModal @apply-filters="handleApplyFilters" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FilterModal from "./FilterModal.vue";

export default defineComponent({
  name: "FilterContent",
  props: ["btnText", "btnLink"],
  components: {
    FilterModal,
  },
  data() {
    return {
      barcode: "",
      filters: {},
    };
  },
  methods: {
    onBarcodeChange() {
      this.$emit("filter-barcode", this.barcode.trim());
    },
    handleApplyFilters(filters: any) {
      this.filters = filters;
      this.$emit("apply-filters", this.filters);
    },
    handleClick() {
      this.$emit("apply-filters", {});
    },
  },
});
</script>

<style scoped>
.btn-clear {
  padding: 0;
}
.btn-clear:focus {
  border: none;
}
/* Tamaño por defecto pantallas (1280-1024) */
@media all and (min-width: 768px) {
  .search-area {
    min-width: 50%;
    margin-left: 20px;
  }
}
</style>
