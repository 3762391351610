<template>
  <MainHeader />
  <MainSidebar />
  <div
    class="main-content bg_gray d-flex flex-column transition overflow-hidden"
  >
    <BreadcrumbMenu pageTitle="Crear Cotización" />
    <div class="row mb-40">
      <!-- Form Fields for Quotation Details -->
      <div class="col-lg-4">
        <div class="form-group mb-25">
          <label class="d-block fs-14 text-black mb-2">Fecha</label>
          <input
            type="date"
            v-model="form.date"
            class="w-100 h-55 bg_ash border-0 rounded-1 fs-14 text-black bg-white"
          />
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group mb-25">
          <label class="d-block fs-14 text-black mb-2">Nombre Vendedor</label>
          <input
            type="text"
            v-model="form.name"
            class="w-100 d-block shadow-none fs-14 bg-white rounded-1 text-title"
            placeholder="Ingresar Nombre del Vendedor"
            required
          />
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group mb-25">
          <label class="d-block fs-14 text-black mb-2">Casa Matriz</label>
          <select
            v-model="form.warehouse"
            class="bg-white border-0 rounded-1 fs-14 text-optional"
          >
            <option value="1">Sucursal Principal</option>
          </select>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group mb-25">
          <label class="d-block fs-14 text-black mb-2">Email Vendedor</label>
          <input
            type="text"
            v-model="form.emailSeller"
            class="w-100 d-block shadow-none fs-14 bg-white rounded-1 text-title"
            placeholder="Ingresar Email del Vendedor"
            required
          />
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group mb-25">
          <label class="d-block fs-14 text-black mb-2">Teléfono Vendedor</label>
          <input
            type="text"
            v-model="form.phoneSeller"
            class="w-100 d-block shadow-none fs-14 bg-white rounded-1 text-title"
            placeholder="Ingresar Teléfono del Vendedor"
            required
          />
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group mb-25">
          <label class="d-block fs-14 text-black mb-2">Nombre Cliente</label>
          <input
            type="text"
            v-model="form.clientName"
            class="w-100 d-block shadow-none fs-14 bg-white rounded-1 text-title"
            placeholder="Ingresar Nombre del Cliente"
            required
          />
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group mb-25">
          <label class="d-block fs-14 text-black mb-2">Email Cliente</label>
          <input
            type="text"
            v-model="form.clientEmail"
            class="w-100 d-block shadow-none fs-14 bg-white rounded-1 text-title"
            placeholder="Ingresar Email del Cliente"
            required
          />
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group mb-25">
          <label class="d-block fs-14 text-black mb-2">Teléfono Cliente</label>
          <input
            type="text"
            v-model="form.clientPhone"
            class="w-100 d-block shadow-none fs-14 bg-white rounded-1 text-title"
            placeholder="Ingresar Teléfono del Cliente"
            required
          />
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group mb-25">
          <label class="d-block fs-14 text-black mb-2">Dirección Cliente</label>
          <input
            type="text"
            v-model="form.clientAddress"
            class="w-100 d-block shadow-none fs-14 bg-white rounded-1 text-title"
            placeholder="Ingresar Dirección del Cliente"
            required
          />
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label class="d-block fs-14 text-black mb-2"
            >Seleccionar Producto</label
          >
          <div class="search-area style-two position-relative w-100">
            <input
              type="text"
              v-model="searchProduct"
              @input="debouncedSearchProduct"
              placeholder="Escanear / Buscar producto por código de barra"
              class="w-100 h-55 bg_ash border-0 rounded-1 fs-14 text-black bg-white"
            />
            <button
              type="button"
              @click="searchProductByCode"
              class="bg-transparent border-0 position-absolute top-0 end-0 h-100 pt-0 py-0 px-2"
            >
              <img src="../../assets/img/icons/search.svg" alt="Image" />
            </button>
          </div>
          <div v-if="errorMessage" class="text-danger mt-2">
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </div>
    <SelectedProducts
      :products="form.products"
      @remove-product="removeProduct"
      @update-product="updateProduct"
    />
    <SubmitQuotation
      :products="form.products"
      :taxRate="form.taxRate"
      :discount="form.discount"
      :shippingCost="form.shippingCost"
      :status="form.status"
      :note="form.note"
      @update:discount="updateDiscount"
      @update:shipping-cost="updateShippingCost"
      @update:status="updateStatus"
      @update:note="updateNote"
      @submit="createQuotation"
      @cancel="showCancelModal"
      @update-subtotal="updateSubtotal"
      @update-tax="updateTax"
      @update-total="updateTotal"
      :isFormValid="isFormValid"
    />
    <div class="flex-grow-1"></div>
    <MainFooter />
  </div>

  <div
    class="delete-popup offcanvas offcanvas-end border-0"
    tabindex="-1"
    id="deletePopup"
  >
    <div class="offcanvas-body p-0">
      <div class="delete-success">
        <img src="../../assets/img/icons/tick-circle.svg" alt="Image" />
        <span class="text-white fw-medium"
          >Your product is deleted from the list.</span
        >
      </div>
    </div>
  </div>
  <SuccessModal
    :title="'La cotización fue creada exitosamente'"
    :message="'Serás redirigido al listado de cotizaciones'"
    :show="showSuccessModal"
    @close="closeSuccessModal"
  />
  <ErrorModal
    :title="errorTitle"
    :messages="errorMessage"
    :show="showErrorModal"
    @close="closeErrorModal"
  />
  <WarningModal
    :title="'Cancelar creación'"
    :message="'¿Estás seguro de que deseas cancelar la creación de la cotización? Se perderán todos los cambios no guardados.'"
    :show="showCancel"
    @close="closeCancelModal"
    @confirm="cancelCreation"
  />
</template>

<script lang="ts">
import { defineComponent, watch, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import MainHeader from "../../components/Layouts/MainHeader.vue";
import MainSidebar from "../../components/Layouts/MainSidebar.vue";
import BreadcrumbMenu from "../../components/Common/BreadcrumbMenu.vue";
import SelectedProducts from "../../components/Quotations/CreateQuotation/SelectedProducts.vue";
import SubmitQuotation from "../../components/Quotations/CreateQuotation/SubmitQuotation.vue";
import MainFooter from "../../components/Layouts/MainFooter.vue";
import SuccessModal from "../../components/Common/SuccessModal.vue";
import ErrorModal from "../../components/Common/ErrorModal.vue";
import WarningModal from "../../components/Common/WarningModal.vue";
import axios from "axios";

interface Product {
  name: string;
  code: string;
  price: number;
  quantity: number;
  brand: string;
  stock: number;
}

export default defineComponent({
  name: "CreateQuotationPage",
  components: {
    MainHeader,
    MainSidebar,
    BreadcrumbMenu,
    SelectedProducts,
    SubmitQuotation,
    MainFooter,
    SuccessModal,
    ErrorModal,
    WarningModal,
  },
  setup() {
    const router = useRouter();

    const form = ref({
      date: "",
      name: "",
      warehouse: "",
      emailSeller: "",
      phoneSeller: "",
      clientName: "",
      clientEmail: "",
      clientPhone: "",
      clientAddress: "",
      products: [] as Product[],
      taxRate: 19,
      discount: 0,
      shippingCost: 0,
      status: "0",
      note: "",
    });

    const isLoadingLocalStorage = ref(true);
    const searchProduct = ref("");
    const errorMessage = ref("");
    const showSuccessModal = ref(false);
    const showErrorModal = ref(false);
    const showCancel = ref(false);
    const errorTitle = ref("");
    const subtotal = ref(0);
    const tax = ref(0);
    const total = ref(0);
    const debouncedSearchProduct = ref<() => void>();

    const loadLocalStorage = () => {
      const savedForm = localStorage.getItem("quotationForm");
      if (savedForm) {
        try {
          const parsedForm = JSON.parse(savedForm);
          if (parsedForm && Object.keys(parsedForm).length > 0) {
            form.value = parsedForm;
          }
        } catch (e) {
          console.error("Error parsing saved form:", e);
        }
      }
      isLoadingLocalStorage.value = false;
    };

    onMounted(() => {
      loadLocalStorage();
      debouncedSearchProduct.value = debounce(searchProductByCode, 1000);

      watch(
        form,
        (newForm) => {
          if (!isLoadingLocalStorage.value) {
            updateLocalStorage();
          }
        },
        { deep: true, immediate: false }
      );
    });

    const createQuotation = async () => {
      const createQuotationDTO = {
        date: form.value.date,
        time: new Date().toLocaleTimeString(),
        warehouse: form.value.warehouse,
        orderTax: tax.value,
        discount: form.value.discount,
        shipping: form.value.shippingCost,
        total: total.value,
        comments: form.value.note,
        status: "0",
        customer: {
          name: form.value.name,
          mail: form.value.emailSeller,
          phone: form.value.phoneSeller,
        },
        client: {
          name: form.value.clientName,
          mail: form.value.clientEmail,
          phone: form.value.clientPhone,
          address: form.value.clientAddress,
        },
        products: form.value.products,
      };

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_AYM_BFF}/v1/quotation`,
          createQuotationDTO
        );
        resetForm();
        showSuccessModal.value = true;
        setTimeout(() => {
          router.push("/quotation");
        }, 2000);
      } catch (error: unknown) {
        console.error("Error creating quotation", error);
        if (axios.isAxiosError(error) && error.response) {
          errorTitle.value = "Error al crear la cotización";
          errorMessage.value = error.response.data.message || error.message;
        } else if (error instanceof Error) {
          errorTitle.value = "Error al crear la cotización";
          errorMessage.value = error.message;
        }
        showErrorModal.value = true;
      }
    };

    const searchProductByCode = async () => {
      if (!searchProduct.value) return;

      try {
        const response = await axios.get(
          `${process.env.VUE_APP_AYM_BFF}/v1/products?code=${searchProduct.value}`
        );
        if (response.data && response.data.products.length > 0) {
          const product: Product = {
            ...response.data.products[0],
            quantity: 1,
          };
          form.value.products.push(product);
          errorMessage.value = "";
          updateLocalStorage();
        } else {
          errorMessage.value = "Producto no encontrado";
        }
        searchProduct.value = "";
      } catch (error: unknown) {
        console.error("Error searching product", error);
        errorMessage.value = "Producto no encontrado";
      }
    };

    const removeProduct = (code: string) => {
      form.value.products = form.value.products.filter(
        (product: Product) => product.code !== code
      );
      updateLocalStorage();
    };

    const updateProduct = (updatedProduct: Product) => {
      const productIndex = form.value.products.findIndex(
        (product) => product.code === updatedProduct.code
      );
      if (productIndex !== -1) {
        form.value.products.splice(productIndex, 1, updatedProduct);
      }
      updateLocalStorage();
    };

    const updateLocalStorage = () => {
      localStorage.setItem("quotationForm", JSON.stringify(form.value));
    };

    const updateDiscount = (newDiscount: number) => {
      form.value.discount = newDiscount;
      updateLocalStorage();
    };

    const updateShippingCost = (newShippingCost: number) => {
      form.value.shippingCost = newShippingCost;
      updateLocalStorage();
    };

    const updateStatus = (newStatus: string) => {
      form.value.status = newStatus;
      updateLocalStorage();
    };

    const updateNote = (newNote: string) => {
      form.value.note = newNote;
      updateLocalStorage();
    };

    const updateSubtotal = (newSubtotal: number) => {
      subtotal.value = newSubtotal;
      updateLocalStorage();
    };

    const updateTax = (newTax: number) => {
      tax.value = newTax;
      updateLocalStorage();
    };

    const updateTotal = (newTotal: number) => {
      total.value = newTotal;
      updateLocalStorage();
    };

    const debounce = (
      func: (...args: any[]) => void,
      wait: number
    ): (() => void) => {
      let timeout: ReturnType<typeof setTimeout> | null = null;
      return (...args: any[]) => {
        const later = () => {
          timeout = null;
          func.apply(this, args);
        };
        if (timeout !== null) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(later, wait);
      };
    };

    const resetForm = () => {
      form.value = {
        date: "",
        name: "",
        warehouse: "",
        emailSeller: "",
        phoneSeller: "",
        clientName: "",
        clientEmail: "",
        clientPhone: "",
        clientAddress: "",
        products: [] as Product[],
        taxRate: 19,
        discount: 0,
        shippingCost: 0,
        status: "0",
        note: "",
      };
      subtotal.value = 0;
      tax.value = 0;
      total.value = 0;
      localStorage.removeItem("quotationForm");
    };

    const closeErrorModal = () => {
      showErrorModal.value = false;
    };

    const closeSuccessModal = () => {
      showSuccessModal.value = false;
    };

    const closeCancelModal = () => {
      showCancel.value = false;
    };

    const showCancelModal = () => {
      showCancel.value = true;
    };

    const cancelCreation = () => {
      resetForm();
      router.push("/quotation");
    };

    const isFormValid = () => {
      return (
        Boolean(form.value.date) &&
        Boolean(form.value.name) &&
        Boolean(form.value.warehouse) &&
        Boolean(form.value.emailSeller) &&
        Boolean(form.value.phoneSeller) &&
        Boolean(form.value.clientName) &&
        Boolean(form.value.clientEmail) &&
        Boolean(form.value.clientPhone) &&
        Boolean(form.value.clientAddress) &&
        form.value.products.length > 0
      );
    };

    return {
      form,
      searchProduct,
      errorMessage,
      showSuccessModal,
      showErrorModal,
      showCancel,
      errorTitle,
      subtotal,
      tax,
      total,
      debouncedSearchProduct,
      createQuotation,
      searchProductByCode,
      removeProduct,
      updateProduct,
      updateDiscount,
      updateShippingCost,
      updateStatus,
      updateNote,
      updateSubtotal,
      updateTax,
      updateTotal,
      resetForm,
      closeErrorModal,
      closeSuccessModal,
      closeCancelModal,
      showCancelModal,
      cancelCreation,
      isFormValid,
    };
  },
});
</script>
