<template>
  <div
    v-if="show"
    class="modal fade show d-block"
    id="detailsModal"
    tabindex="-1"
    aria-labelledby="detailsModalLabel"
    aria-hidden="true"
    style="background-color: rgba(0, 0, 0, 0.5)"
  >
    <div
      class="modal-dialog modal-lg modal-xxl modal-dialog-centered modal-dialog-scrollable"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-title" id="detailsModalLabel">
            Detalle Cotización
          </h5>
          <button
            type="button"
            class="btn-close p-0"
            @click="closeModal"
            aria-label="Close"
          >
            <img
              src="../../../assets/img/icons/close-circle-2.svg"
              alt="Image"
            />
          </button>
        </div>
        <div class="modal-body pb-40">
          <div class="row">
            <div class="col-lg-4">
              <h6 class="fs-16 fw-bold text-title mb-20">
                Información Cotización:
              </h6>
              <ul class="details-title list-style mb-40">
                <li class="fs-14 fw-medium text-title lh-1">
                  FECHA :<span class="fw-semibold ms-1">{{
                    selectedQuotation.date
                  }}</span>
                </li>
                <li class="fs-14 fw-medium text-title lh-1">
                  HORA :<span class="fw-semibold ms-1">{{
                    selectedQuotation.time
                  }}</span>
                </li>
                <li class="fs-14 fw-medium text-title lh-1">
                  REFERENCIA :<span class="fw-semibold ms-1">{{
                    selectedQuotation.codReference
                  }}</span>
                </li>
                <li class="fs-14 fw-medium text-title lh-1">
                  CASA MATRIZ :<span class="fw-semibold ms-1">{{
                    $filters.translateCodeWareHouse(selectedQuotation.warehouse)
                  }}</span>
                </li>
              </ul>
            </div>
            <div class="col-lg-4 ps-xxl-6">
              <h6 class="fs-16 fw-bold text-title mb-20">
                Información Vendedor:
              </h6>
              <ul class="details-title list-style mb-40">
                <li class="fs-14 fw-semibold text-title lh-1">
                  NOMBRE :<span class="ms-1 text-optional">{{
                    selectedQuotation.customer.name
                  }}</span>
                </li>
                <li class="fs-14 fw-semibold text-title lh-1">
                  EMAIL :
                  <span class="text-optional ms-1">{{
                    selectedQuotation.customer.mail
                  }}</span>
                </li>
                <li class="fs-14 fw-semibold text-title lh-1">
                  TELÉFONO :<span class="text-optional ms-1">{{
                    selectedQuotation.customer.phone
                  }}</span>
                </li>
              </ul>
            </div>
            <div class="col-lg-4 ps-xxl-6">
              <h6 class="fs-16 fw-bold text-title mb-20">
                Información Cliente:
              </h6>
              <ul class="details-title list-style mb-40">
                <li class="fs-14 fw-semibold text-title lh-1">
                  NOMBRE :<span class="ms-1 text-optional">{{
                    selectedQuotation.client.name
                  }}</span>
                </li>
                <li class="fs-14 fw-semibold text-title lh-1">
                  EMAIL :<span class="text-optional ms-1">{{
                    selectedQuotation.client.mail
                  }}</span>
                </li>
                <li class="fs-14 fw-semibold text-title lh-1">
                  TELÉFONO :<span class="text-optional ms-1">{{
                    selectedQuotation.client.phone
                  }}</span>
                </li>
                <li class="fs-14 fw-semibold text-title lh-1">
                  DIRECCIÓN :<span class="text-optional ms-1">{{
                    selectedQuotation.client.address
                  }}</span>
                </li>
              </ul>
            </div>
          </div>

          <div class="table-responsive style-two">
            <table class="table text-nowrap align-middle mb-0 border-0">
              <thead>
                <tr class="bg_mild">
                  <th
                    scope="col"
                    class="text-title fw-normal fs-14 lh-1 bg_mild"
                  >
                    NO.
                  </th>
                  <th
                    scope="col"
                    class="text-title fw-normal fs-14 lh-1 bg_mild"
                  >
                    PRODUCTO
                  </th>
                  <th
                    scope="col"
                    class="text-title fw-normal fs-14 lh-1 bg_mild"
                  >
                    CÓDIGO
                  </th>
                  <th
                    scope="col"
                    class="text-title fw-normal fs-14 lh-1 bg_mild"
                  >
                    PRECIO UNITARIO
                  </th>
                  <th
                    scope="col"
                    class="text-title fw-normal fs-14 lh-1 bg_mild"
                  >
                    CANTIDAD
                  </th>
                  <th
                    scope="col"
                    class="text-title fw-normal fs-14 lh-1 bg_mild"
                  >
                    SUBTOTAL
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(product, index) in selectedQuotation.products"
                  :key="index"
                  class="br-s-1"
                >
                  <td
                    class="shadow-none lh-1 fs-14 fw-semibold text-paragraph br-s-1"
                  >
                    {{ index + 1 }}.
                  </td>
                  <td class="shadow-none lh-1 fs-14 fw-normal text-paragraph">
                    {{ product.name }}
                  </td>
                  <td class="shadow-none lh-1 fs-14 fw-normal text-paragraph">
                    {{ product.code }}
                  </td>
                  <td class="shadow-none lh-1 fs-14 fw-normal text-paragraph">
                    ${{ $filters.formatNumber(product.price) }}
                  </td>
                  <td class="shadow-none lh-1 fs-14 fw-normal text-paragraph">
                    {{ product.quantity }}
                  </td>
                  <td class="shadow-none lh-1 fs-14 fw-normal text-paragraph">
                    ${{
                      $filters.formatNumber(product.price * product.quantity)
                    }}
                  </td>
                </tr>
                <tr>
                  <td colspan="4"></td>
                  <td
                    class="shadow-none lh-1 fs-14 fw-normal text-title float-right br-s-1"
                  >
                    SUBTOTAL GENERAL
                  </td>
                  <td
                    class="shadow-none lh-1 fs-14 fw-semibold text-paragraph float-right"
                  >
                    ${{
                      $filters.formatNumber(
                        selectedQuotation.total -
                          selectedQuotation.orderTax +
                          selectedQuotation.discount -
                          selectedQuotation.shipping
                      )
                    }}
                  </td>
                </tr>
                <tr>
                  <td colspan="4"></td>
                  <td
                    class="shadow-none lh-1 fs-14 fw-normal text-title float-right br-s-1"
                  >
                    IVA
                  </td>
                  <td
                    class="shadow-none lh-1 fs-14 fw-semibold text-paragraph float-right"
                  >
                    ${{ $filters.formatNumber(selectedQuotation.orderTax) }}
                  </td>
                </tr>
                <tr>
                  <td colspan="4"></td>
                  <td
                    class="shadow-none lh-1 fs-14 fw-normal text-title float-right br-s-1"
                  >
                    ENVÍO
                  </td>
                  <td class="shadow-none lh-1 fs-14 fw-normal text-paragraph">
                    ${{ $filters.formatNumber(selectedQuotation.shipping) }}
                  </td>
                </tr>
                <tr>
                  <td colspan="4"></td>
                  <td
                    class="shadow-none lh-1 fs-14 fw-normal text-title float-right br-s-1"
                  >
                    DESCUENTO
                  </td>
                  <td class="shadow-none lh-1 fs-14 fw-normal text-paragraph">
                    ${{ $filters.formatNumber(selectedQuotation.discount) }}
                  </td>
                </tr>
                <tr>
                  <td colspan="4"></td>
                  <td class="shadow-none lh-1 fs-14 fw-semibold text-title">
                    TOTAL GENERAL
                  </td>
                  <td class="shadow-none lh-1 fs-14 fw-black text-purple">
                    ${{ $filters.formatNumber(selectedQuotation.total) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="mt-4">
            <h6 class="fs-16 fw-bold text-title mb-20">Notas:</h6>
            <p>{{ selectedQuotation.comments }}</p>
          </div>
        </div>
        <div
          class="modal-footer pt-25 pb-35 d-flex flex-wrap justify-content-end"
        >
          <a class="btn style-five upload-btn px-xxl-6" @click="downloadPDF">
            Descargar PDF
            <img src="../../../assets/img/icons/download.svg" alt="Image" />
          </a>
          <a class="btn btn-warning px-xxl-6" @click="closeModal"> Cerrar </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import axios from "axios";
import { formatNumber, translateCodeWareHouse } from "../../../utils/filters"; // Importa las funciones de utilidad

export default defineComponent({
  name: "QuotationDetails",
  props: {
    selectedQuotation: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    transformData(quotation: any) {
      return {
        date: quotation.date,
        time: quotation.time,
        codReference: quotation.codReference,
        warehouse: quotation.warehouse,
        customer: {
          name: quotation.customer.name,
          mail: quotation.customer.mail,
          phone: quotation.customer.phone,
        },
        client: {
          name: quotation.client.name,
          mail: quotation.client.mail,
          phone: quotation.client.phone,
          address: quotation.client.address,
        },
        products: quotation.products.map((product: any) => ({
          0: {
            name: product.name,
            code: product.code,
            price: product.price,
          },
          quantity: product.quantity,
        })),
        subtotalGeneral:
          quotation.total -
          quotation.orderTax +
          quotation.discount -
          quotation.shipping,
        iva: quotation.orderTax,
        envio: quotation.shipping,
        descuento: quotation.discount,
        totalGeneral: quotation.total,
        comments: quotation.comments,
      };
    },
    async downloadPDF() {
      try {
        const transformedData = this.transformData(this.selectedQuotation);
        const response = await axios.post(
          `${process.env.VUE_APP_AYM_BFF}/v1/quotation-pdf`,
          transformedData,
          { responseType: "blob" }
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "quotation.pdf");
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    },
  },
});
</script>
