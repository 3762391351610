export function formatNumber(value: number | string): string {
  if (!value) {
    return "0";
  }
  return Number(value).toLocaleString("es-CL");
}

export function unformatNumber(value: string): number {
  return Number(value.replace(/\./g, ""));
}

export function formatDate(date: string): string {
  const [year, month, day] = date.split("-");
  return `${day}-${month}-${year}`;
}

export function translateCodeWareHouse(wareHouse: number) {
  const wareHouses: string[] = ["Sucursal Principal"];
  const isValidWareHouse: number | boolean =
    wareHouse && wareHouse <= wareHouses.length && wareHouse >= 0;
  if (isValidWareHouse) {
    return wareHouses[wareHouse - 1];
  } else {
    return "No registrada";
  }
}
