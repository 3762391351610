<template>
  <button class="btn style-six" @click.prevent="openBackModal">
    <slot>Volver</slot>
  </button>
  <WarningModal
    :title="'Confirmar acción'"
    :message="'¿Estás seguro de que deseas salir de esta vista? Se perderán todos los cambios no guardados.'"
    :show="showBackModal"
    @close="showBackModal = false"
    @confirm="confirmBack"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import WarningModal from "../Common/WarningModal.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "BackButton",
  components: { WarningModal },
  data() {
    return {
      showBackModal: false,
    };
  },
  methods: {
    openBackModal() {
      this.showBackModal = true;
    },
    confirmBack() {
      this.$router.back();
    },
  },
  setup() {
    const router = useRouter();

    return {
      router,
    };
  },
});
</script>

<style scoped>
.ml-10 {
  margin-left: 10px;
}

.btn.style-six {
  margin-left: 20px !important;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #eaa00a;
  color: #eaa00a;
}
.btn.style-six:hover {
  color: #fff;
}
.btn.style-six:before {
  background: #eaa00a;
}
</style>
