<template>
  <div class="card border-0 shadow-none rounded-1 mb-30">
    <div class="card-body p-xl-40">
      <h6 class="fs-18 mb-35 text-title fw-semibold">
        Productos Seleccionados Para Cotización
      </h6>
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th scope="col" class="text-title fw-normal fs-14 pt-0 ls-1">
                CÓDIGO
              </th>
              <th scope="col" class="text-title fw-normal fs-14 pt-0 ps-0 ls-1">
                PRODUCTO
              </th>
              <th scope="col" class="text-title fw-normal fs-14 pt-0 ps-0 ls-1">
                MARCA
              </th>
              <th scope="col" class="text-title fw-normal fs-14 pt-0 ls-1">
                PRECIO UNITARIO
              </th>
              <th scope="col" class="text-title fw-normal fs-14 pt-0 ls-1">
                STOCK
              </th>
              <th scope="col" class="text-title fw-normal fs-14 pt-0 ls-1">
                CANTIDAD
              </th>
              <th scope="col" class="text-title fw-normal fs-14 pt-0 ls-1">
                SUBTOTAL
              </th>
              <th
                scope="col"
                class="text-title fw-normal fs-14 pt-0 ls-1 text-end pe-0"
              >
                ELIMINAR
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="product in products" :key="product.code">
              <td class="shadow-none lh-1 fs-14 fw-normal text-paragraph">
                {{ product.code }}
              </td>
              <td class="shadow-none lh-1 fs-14 fw-normal text-paragraph ps-0">
                {{ product.name }}
              </td>
              <td class="shadow-none lh-1 fs-14 fw-normal text-paragraph">
                {{ product.brand }}
              </td>
              <td class="shadow-none lh-1 fs-14 fw-normal text-paragraph">
                $ {{ product.price }}
              </td>
              <td class="shadow-none lh-1 fs-14 fw-normal text-paragraph">
                <span class="badge badge-success fw-semibold fs-14">
                  {{ product.stock }}
                </span>
              </td>
              <td class="shadow-none lh-1 fs-14 fw-normal text-paragraph">
                <QuantityCounter
                  v-model="product.quantity"
                  @update:quantity="updateQuantity(product)"
                />
              </td>
              <td class="shadow-none lh-1 fs-14 fw-normal text-paragraph">
                $ {{ product.price * product.quantity }}
              </td>
              <td class="shadow-none lh-1 text-end pe-0">
                <div
                  class="button-group style-two ms-auto d-flex flex-wrap align-items-center"
                >
                  <a
                    class="delete-btn"
                    @click="removeProduct(product.code)"
                    role="button"
                  >
                    <img
                      src="../../../assets/img/icons/close.svg"
                      alt="Image"
                    />
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import QuantityCounter from "./QuantityCounter.vue";

interface Product {
  name: string;
  code: string;
  price: number;
  quantity: number;
  brand: string;
  stock: number;
}

export default defineComponent({
  name: "SelectedProducts",
  components: {
    QuantityCounter,
  },
  props: {
    products: {
      type: Array as PropType<Product[]>,
      required: true,
    },
  },
  methods: {
    removeProduct(code: string) {
      this.$emit("remove-product", code);
    },
    updateQuantity(product: Product) {
      this.$emit("update-product", product);
    },
  },
});
</script>
