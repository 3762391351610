import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1fe47359"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row mb-25 pb-18 align-items-end br-1 gx-0" }
const _hoisted_2 = { class: "col-md-6" }
const _hoisted_3 = { class: "mb-0" }
const _hoisted_4 = { class: "col-md-6" }
const _hoisted_5 = { class: "breadcrumb-menu list-style lh-base text-md-end" }
const _hoisted_6 = { class: "active" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.pageTitle), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("ul", _hoisted_5, [
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/inventory" }, {
            default: _withCtx(() => [
              _createTextVNode("Inicio")
            ]),
            _: 1
          })
        ]),
        _createElementVNode("li", _hoisted_6, _toDisplayString(_ctx.pageTitle), 1)
      ])
    ])
  ]))
}