<template>
  <div
    class="card border-0 shadow-none rounded-1 mb-40"
    v-if="product && product.length > 0"
  >
    <div class="card-body p-xl-60">
      <div class="row gx-xxl-6">
        <div class="col-xl-4 col-lg-5 pe-xxl-1">
          <div class="single-product-img">
            <img :src="product[0].image" alt="Product" />
          </div>
        </div>
        <div class="col-xl-8 col-lg-7 ps-xxl-6">
          <table class="table single-product-table mb-60">
            <thead>
              <tr>
                <th scope="col">Tipo</th>
                <th scope="col">Detalle</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Código de Barra</th>
                <td>{{ product[0].code }}</td>
              </tr>
              <tr>
                <th scope="row">Código SKU</th>
                <td>{{ product[0].sku }}</td>
              </tr>
              <tr>
                <th scope="row">Nombre de Producto</th>
                <td>{{ product[0].name }}</td>
              </tr>
              <tr>
                <th scope="row">Categoría</th>
                <td>{{ product[0].category }}</td>
              </tr>
              <tr>
                <th scope="row">Marca</th>
                <td>{{ product[0].brand }}</td>
              </tr>
              <tr>
                <th scope="row">Precio Venta</th>
                <td>${{ $filters.formatNumber(product[0].price) }}</td>
              </tr>
              <tr>
                <th scope="row">Costo</th>
                <td>${{ $filters.formatNumber(product[0].cost) }}</td>
              </tr>
              <tr>
                <th scope="row">Descripción</th>
                <td>{{ product[0].description }}</td>
              </tr>
              <tr>
                <th scope="row">Stock Disponible</th>
                <td>
                  <span class="badge badge-outline-green fs-14 fw-semibold">
                    {{ $filters.formatNumber(product[0].stock) }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col">
          <StockHistoryList :productId="productID" />
          <button class="btn btn-danger" @click="openDeleteModal()">
            Eliminar
          </button>
          <button
            class="btn style-six"
            @click="
              () => {
                this.$router.back();
              }
            "
          >
            Volver
          </button>
        </div>
      </div>
      <WarningModal
        :title="'El producto será eliminado y no se podrá recuperar.'"
        :message="'¿Estás seguro de que deseas eliminar este producto?'"
        :show="showDeleteModal"
        @close="showDeleteModal = false"
        @confirm="confirmDelete(product[0]._id)"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import WarningModal from "../../Common/WarningModal.vue";
import { useRouter } from "vue-router";
import StockHistoryList from "../ProductStockHistory/ProductStockHistoryList.vue";
export default {
  name: "ProductDetails",
  components: { WarningModal, StockHistoryList },
  data() {
    return {
      product: [],
      showDeleteModal: false,
      productID: this.$route.params.id,
    };
  },
  async created() {
    this.product = await this.getProductDetails(this.productID);
  },
  methods: {
    async getProductDetails(productID) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.VUE_APP_AYM_BFF}/v1/product?param=id&value=${productID}`,
        headers: {},
      };

      try {
        let response = await axios.request(config);
        return response.data; // assumes the API returns an array
      } catch (error) {
        console.error(error);
      }
    },
    async confirmDelete(productID) {
      const toast = useToast();

      console.log("entra aca?");
      console.log("productID", productID);

      if (productID) {
        try {
          await axios.delete(
            `${process.env.VUE_APP_AYM_BFF}/v1/product/${productID}`
          );
          setTimeout(() => {
            this.showDeleteModal = false;
            toast.success("Producto eliminado correctamente.");
            this.$router.push("/inventory");
          }, 1000);
        } catch (error) {
          console.error("Error deleting quotation", error);
          toast.error("No se pudo eliminar el producto, intenta más tarde.");
        }
      } else {
        toast.error("No se pudo eliminar el producto, intenta más tarde.");
      }
    },
    openDeleteModal() {
      this.showDeleteModal = true;
    },
  },
  setup() {
    const router = useRouter();

    return {
      router,
      StockHistoryList,
    };
  },
};
</script>

<style>
.btn.style-six {
  margin-left: 20px !important;
  background-color: #fff;
  border: 1px solid #eaa00a;
  color: #eaa00a;
}
.btn.style-six:hover {
  color: #fff;
}
.btn.style-six:before {
  background: #eaa00a;
}
</style>
