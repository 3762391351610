<template>
  <div v-if="show" class="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
        <img
          src="https://static.vecteezy.com/system/resources/thumbnails/012/042/301/small_2x/warning-sign-icon-transparent-background-free-png.png"
          class="sizeImage"
        />
        <p>
          <b>{{ message }}</b>
        </p>
        <p>
          <small>{{ title }}</small>
        </p>
        <div class="modal-actions">
          <button class="btn btn-danger mt-3" @click="confirmAction">
            Confirmar
          </button>
          <button class="btn btn-warning mt-3" @click="closeModal">
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    title: String,
    message: String,
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    confirmAction() {
      this.$emit("confirm");
      this.closeModal();
    },
  },
};
</script>

<style scoped>
.modal {
  display: flex;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}
.modal-dialog {
  max-width: 500px;
  margin: 100px auto;
}
.modal-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: 500px;
  height: 350px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.close {
  position: absolute;
  top: 10px;
  right: 20px;
  color: #aaa;
  font-size: 24px;
  cursor: pointer;
}
.sizeImage {
  height: 80px;
  width: 80px;
  margin-bottom: 30px;
}
.modal-actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.modal-actions button {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  flex: 1;
}
</style>
