<template>
  <div class="card border-0 shadow-none rounded-1 mb-25">
    <div class="card-body p-xl-40">
      <div class="mb-5">
        <h3>Historial de movimientos</h3>
      </div>
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th scope="col" class="text-title fw-normal fs-14 pt-0">
                <b>FECHA</b>
              </th>
              <th
                scope="col"
                class="text-title fw-normal fs-14 pt-0 text-center"
              >
                <b>STOCK INICIAL</b>
              </th>
              <th
                scope="col"
                class="text-title fw-normal fs-14 pt-0 text-center"
              >
                <b>ACCIÓN</b>
              </th>
              <th
                scope="col"
                class="text-title fw-normal fs-14 pt-0 text-center"
              >
                <b>CANTIDAD</b>
              </th>
              <th
                scope="col"
                class="text-title fw-normal fs-14 pt-0 text-center"
              >
                <b>STOCK FINAL</b>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="history in stockHistory" :key="history._id">
              <td class="shadow-none lh-1 fs-14 fw-normal text-paragraph">
                <b>{{ new Date(history.date).toLocaleString() }}</b>
              </td>
              <td
                class="shadow-none lh-1 fs-14 fw-bold text-paragraph text-center"
              >
                {{ history.initialStock }}
              </td>
              <td
                :class="history.action"
                class="shadow-none lh-1 fs-14 fw-normal text-paragraph text-center"
              >
                {{ translateAction(history.action) }}
              </td>
              <td
                :class="history.action"
                class="shadow-none lh-1 fs-14 fw-normal text-paragraph text-center"
              >
                {{ history.quantity }}
              </td>
              <td
                class="shadow-none lh-1 fs-16 fw-normal text-paragraph text-center"
              >
                <b>{{ history.finalStock }}</b>
              </td>
            </tr>
          </tbody>
          <div class="not-found-products" v-show="stockHistory.length === 0">
            No se encontró historial para este producto.
          </div>
        </table>
      </div>
      <div class="row pb-45 align-items-center">
        <div class="col-sm-6">
          <div
            class="d-flex flex-wrap align-items-center justify-content-center justify-content-sm-start page-unit"
          >
            <span class="fs-13"
              >Historial por página <b>{{ pageSize }}</b></span
            >
          </div>
        </div>
        <div class="col-sm-6 text-sm-end text-center mt-5">
          <ul class="page-nav list-style">
            <li>
              <a
                href="#"
                @click.prevent="changePage(currentPage - 1)"
                :class="{ disabled: currentPage === 1 }"
              >
                <img
                  src="../../../assets/img/icons/left-arrow-purple.svg"
                  alt="Image"
                />
              </a>
            </li>
            <li v-for="page in totalPages" :key="page">
              <a
                href="#"
                :class="{ active: page === currentPage }"
                @click.prevent="changePage(page)"
                >{{ page }}</a
              >
            </li>
            <li>
              <a
                href="#"
                @click.prevent="changePage(currentPage + 1)"
                :class="{ disabled: currentPage === totalPages }"
              >
                <img
                  src="../../../assets/img/icons/right-arrow-purple.svg"
                  alt="Image"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";

interface StockHistoryResponse {
  stockHistory: StockHistory[];
  total: number;
}

interface StockHistory {
  _id: string;
  date: string;
  action: string;
  quantity: number;
  initialStock: number;
  finalStock: number;
  productId: string;
}

export default defineComponent({
  name: "StockHistoryList",
  props: {
    productId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      stockHistory: [] as StockHistory[],
      currentPage: 1,
      pageSize: 10,
      totalPages: 0,
      totalItems: 0,
    };
  },
  mounted() {
    this.fetchStockHistory();
  },
  watch: {
    productId: "fetchStockHistory",
  },
  methods: {
    async fetchStockHistory() {
      const params: any = {
        page: this.currentPage,
        limit: this.pageSize,
      };

      const queryString = Object.keys(params)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
        )
        .join("&");

      try {
        const response = await axios.get<StockHistoryResponse>(
          `${process.env.VUE_APP_AYM_BFF}/v1/stock-history/${this.productId}?${queryString}`
        );

        if (response.data && response.data.stockHistory) {
          this.stockHistory = response.data.stockHistory;
          this.totalItems = response.data.total;
          this.totalPages = Math.ceil(this.totalItems / this.pageSize);
        } else {
          this.stockHistory = [];
          this.totalPages = 0;
        }
      } catch (error) {
        console.error("Error fetching stock history:", error);
        this.stockHistory = [];
        this.totalPages = 0;
      }
    },
    changePage(page: number) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
        this.fetchStockHistory();
      }
    },
    translateAction(action: string) {
      return action === "decrease" ? "EGRESO" : "INGRESO";
    },
  },
});
</script>

<style>
td.increase {
  color: green !important;
  font-weight: bold !important;
}
td.decrease {
  color: red !important;
  font-weight: bold !important;
}
.sizeImage {
  height: 60px;
  width: 60px;
  margin-left: 15px;
}
.page-nav .disabled {
  pointer-events: none;
  opacity: 0.5;
}
.page-nav .active {
  font-weight: bold;
  color: purple;
}
.not-found-products {
  margin-top: 25px;
  font-size: 18px;
  font-weight: bold;
}
.table tbody tr {
  height: 50px; /* Ajusta este valor según tus necesidades */
}
</style>
